// Locales - common
import common_pl from "../../../../libs/ps/restaumatic-common/locales/pl.json";
import common_en from "../../../../libs/ps/restaumatic-common/locales/en.json";
import common_ro from "../../../../libs/ps/restaumatic-common/locales/ro.json";
import common_cs from "../../../../libs/ps/restaumatic-common/locales/cs.json";
import common_sk from "../../../../libs/ps/restaumatic-common/locales/sk.json";
import common_hr from "../../../../libs/ps/restaumatic-common/locales/hr.json";
import common_de from "../../../../libs/ps/restaumatic-common/locales/de.json";
import common_nl from "../../../../libs/ps/restaumatic-common/locales/nl.json";
import common_es from "../../../../libs/ps/restaumatic-common/locales/es.json";
import common_enGB from "../../../../libs/ps/restaumatic-common/locales/en-GB.json";
import common_ptBR from "../../../../libs/ps/restaumatic-common/locales/pt-BR.json";

export default {
  pl: { _common: common_pl },
  en: { _common: common_en },
  ro: { _common: common_ro },
  cs: { _common: common_cs },
  sk: { _common: common_sk },
  hr: { _common: common_hr },
  de: { _common: common_de },
  nl: { _common: common_nl },
  es: { _common: common_es },
  "en-GB": { _common: common_enGB },
  "pt-BR": { _common: common_ptBR },
};
