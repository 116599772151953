// Locales - core-types-sites
import core_types_sites_pl from "../../../../libs/ps/restaumatic-core-types-sites/locales/pl.json";
import core_types_sites_en from "../../../../libs/ps/restaumatic-core-types-sites/locales/en.json";
import core_types_sites_ro from "../../../../libs/ps/restaumatic-core-types-sites/locales/ro.json";
import core_types_sites_cs from "../../../../libs/ps/restaumatic-core-types-sites/locales/cs.json";
import core_types_sites_sk from "../../../../libs/ps/restaumatic-core-types-sites/locales/sk.json";
import core_types_sites_hr from "../../../../libs/ps/restaumatic-core-types-sites/locales/hr.json";
import core_types_sites_de from "../../../../libs/ps/restaumatic-core-types-sites/locales/de.json";
import core_types_sites_nl from "../../../../libs/ps/restaumatic-core-types-sites/locales/nl.json";
import core_types_sites_es from "../../../../libs/ps/restaumatic-core-types-sites/locales/es.json";
import core_types_sites_enGB from "../../../../libs/ps/restaumatic-core-types-sites/locales/en-GB.json";
import core_types_sites_ptBR from "../../../../libs/ps/restaumatic-core-types-sites/locales/pt-BR.json";

export default {
  pl: { _core_types_sites: core_types_sites_pl },
  en: { _core_types_sites: core_types_sites_en },
  ro: { _core_types_sites: core_types_sites_ro },
  cs: { _core_types_sites: core_types_sites_cs },
  sk: { _core_types_sites: core_types_sites_sk },
  hr: { _core_types_sites: core_types_sites_hr },
  de: { _core_types_sites: core_types_sites_de },
  nl: { _core_types_sites: core_types_sites_nl },
  es: { _core_types_sites: core_types_sites_es },
  "en-GB": { _core_types_sites: core_types_sites_enGB },
  "pt-BR": { _core_types_sites: core_types_sites_ptBR },
};
